<template>
	<div class="row" align-y="center" gutter="30">
		<p v-if="labelVisible">Follow us:</p>
		<div class="row" align-y="center" gutter="20">
			<a href="https://www.facebook.com/gbt.tw/" target="_blank">
				<img v-if="white" src="@/assets/icon-facebook-white.png" height="20">
				<img v-else src="@/assets/icon-facebook.png" height="20">
			</a>
			<a href="https://www.youtube.com/user/goldenbough1993/featured" target="_blank">
				<img v-if="white" src="@/assets/icon-youtube-white.png" height="15">
				<img v-else src="@/assets/icon-youtube.png" height="15">
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		"label-visible": Boolean,
		"white": Boolean
	}
}
</script>